.slider-wrapper {
max-width: 620px;
margin: auto;
margin-bottom: 3em;
}

.slider-wrapper::after {
  content: "";
  clear: both;
  display: table;
}

.slider {
  position: relative;
  width: 100vw;
  max-width: 620px;
  margin: auto;
  min-height: 192px;
  /* background: #f4f4f4; */
  border-radius: 0px;
  display: flex;
  align-items: center;
  /* margin-bottom: 3em; */

}

.slider-controls {
  position: absolute;
  width: 120px;
  left: calc(100% - 120px);
  display: flex;
  justify-content: space-between;
  top: -116px;
}

.slider-controls button {
  width: 48%;
  background: none;
  font-size: 1.333em;
  border: none;
  /* box-shadow: rgba(0,0,0, 0.5); */
  height: 42px;
  cursor: pointer;
}

.slider-controls button svg {
  width: 36px;
  height: 36px;
  margin-top: 2px;
  opacity: 1;

}

@media only screen and (max-width: 640px) {
  .slider {
    /* max-width: 320px; */
    width: 100%
  }

  .slide {
    width: 100%;
  }
}
