@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Saira";
  src: local("Saira Thin"), url("./assets/fonts/Saira.woff") format("woff");
}

@font-face {
  font-family: 'Neutro';
  src: local('Neutro Extra Bold'), url("./assets/fonts/Neutro.woff") format('woff');
}

@font-face {
  font-family: 'IvyOra Display Regular';
  src: local('IvyOra Display Regular'), url("./assets/fonts/IvyOraDisplay-Regular.woff") format('woff');
}

@font-face {
  font-family: 'IvyOra Text Regular';
  src: local('IvyOra Text Regular'), url("./assets/fonts/IvyOraText-Regular.woff") format('woff');
}

@font-face {
  font-family: 'Daelort';
  src: local('Daelort'), url("./assets/fonts/Daelort.woff") format('woff');
}
