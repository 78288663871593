.rotating-container {
    position: relative;
    width: 100%;
    height: 100%;
    perspective: 1000px;
  }
  
  .rotating-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform-style: preserve-3d;
    transition: transform 0.5s ease;
    transform: translate(-50%, -50%)
      rotateX(calc(0.05turn * var(--mouseY)))
      rotateY(calc(-0.05turn * var(--mouseX)));
  }