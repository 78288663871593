.slide {
  position: absolute;
  transition: 0.5s;
  opacity: 1;
  /* background-image: var(--primary_gradient); */
  display: flex;
  align-items: center;
  width: 568px;
  left: calc(50% - 284px);
  margin: auto;
  /* box-shadow: 0px 0px 8px 0px var(--accent_clr);; */
  /* border-radius: 35% / 12px; */
  /* border: 1px solid #cacaca; */
  padding: 24px;
  font-family: "Josefin Sans", sans-serif;
  color: #fff;
  /* border: 3px solid #e7ebff; */
  /* font-weight: 700; */
  height: 240px;
}

@media only screen and (max-width: 640px) {
  .slide {
    width: calc(100% - 4rem);
    margin-left: 2rem;
    left: 0;
  }

.slide .image-container {
  display: none;
}
}

@media only screen and (max-width: 560px) {
  .slide {
    width: calc(100% - 3rem);
    margin-left: 1.5rem;
    left: 0;
  }
}

@media only screen and (max-width: 500px) {
  .slide {
    width: calc(100% - 2rem);
    margin-left: 1rem;
    left: 0;
  }
}

@media only screen and (max-width: 460px) {
  .slide {
    width: calc(100% - 2rem);
    margin-left: 1rem;
    left: 0;
  }
}

@media only screen and (max-width: 420px) {
  .slide {
    width: calc(100% - 2rem);
    margin-left: 1rem;
    left: 0;
    padding: 0px 12px;
    height: 280px;
  }
}

.slide:not(.active-slide) {
  opacity: 0;
}

.slide.prev-slide {
  left: 0%;
}

.next-slide {
  left: 50%;
}

.slide.active-slide {
  opacity: 1;
}
