
html {
  /* width: 100% !important; */
}

.fade-in {
  /* opacity: 0; */
  /* animation-fill-mode: forwards; */
  animation-name: fadeIn;
  animation-duration: 2s;
}

.delay-1000 {
  animation-delay: 1s;
}

.white-text-outline {
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #fff;
}

.header-outline {
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
}

.header-outline.header-outline-purple {
  -webkit-text-stroke-color: #A481A6;
  }

.brightness-in {
  filter: brightness(1) saturate(1);
  animation-name: brightnessOut;
  animation-duration: 1s;
}

.content-card {
  background: #fff4;
  backdrop-filter: blur(20px) saturate(5);
}

.no-text-outlined {
  -webkit-text-stroke-width: 0;
  text-shadow: none;
}

.text-outlined {
  background: transparent;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: #28262a;
}

.text-outlined-white {
  /* color: #fff; */
  text-shadow:
   -1px -1px 3px #fcc7ff,
    1px -1px 3px #fcc7ff,
    -1px 1px 3px #fcc7ff,
     1px 1px 3px #fcc7ff;
}

.colour-filter-giek-purple {
  filter: invert(58%) sepia(5%) saturate(2214%) hue-rotate(249deg) brightness(95%) contrast(85%);
}

.rings {
  filter: url('#wavy');
}


.rings-2 {
  filter: url('#wavy2');
}

.rings-2-giek {
  filter: invert(1) brightness(1.333) url('#wavy3')
}

.rings-3-giek {
  filter: invert(1) brightness(8) contrast(10) hue-rotate(30deg) url('#wavy3');
}


.rings-3 {
  filter: url('#wavy3');
}

.scale-up {
  animation-name: scaleUp;
  animation-duration: 2s;
}

.slide-across-screen-one {
  animation: slideAcrossScreen 36s linear infinite;
}

.slide-across-screen-two {
  animation: slideAcrossScreen 48s linear infinite;
}

.slide-across-screen-three {
  animation: slideAcrossScreen 60s linear infinite;
}


@keyframes scaleUp {
  0% {transform: scale(1)}
  100% {translate: scale(3)}
}

@keyframes fadeIn {
  0% {opacity: 0}
  100% {opacity: 1}
}


@keyframes brightnessOut {
  0% {filter: brightness(10) saturate(0)}
  100% {filter: brightness(1) }
}

@keyframes slideAcrossScreen {
  0% {transform: translate(100vw)}
  100% { transform: translate(-728px) }
}

.blur-panel-hero {
  background: #fff4;
  box-shadow: 0px 0px 8px 0px #fffa;
  backdrop-filter: blur(18px);
  /* border-radius: 100% */
}


.blur-panel {
  backdrop-filter: blur(12px)
}

.blur-panel-mid {
  backdrop-filter: blur(4px)
}

.blur-panel-light {
  backdrop-filter: blur(2px)
}

.blog-content p, .blog-content iframe {
  padding: 1rem 0;
  margin: auto;
  line-height: 2rem;
  font-weight: 700;
}

.blog-content .wp-block-gallery {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.blog-content .wp-block-gallery .wp-block-image {
  object-fit: cover;
  margin-bottom: 1rem;
}

.tour-dates h1 {
  font-family: 'Daelort';
  font-size: 1.5rem;
  color: #28262a;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-top: 2.5rem;
}

.tour-dates h2 {
  font-family: 'Daelort';
  font-size: 4rem;
  color: #28262a;
  /* font-weight: 700; */
  margin-bottom: 2.5rem;
  margin-top: 5rem;
}

.wp-block-embed__wrapper {
  position: relative;
overflow: hidden;
width: 100%;
padding-left: 1rem;
padding-right: 1rem;
padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.wp-block-embed__wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;

}

.bg-invert {
  backdrop-filter: invert(1) hue-rotate(45deg) saturate(0.5);
}

.bg-invert-blur {
  backdrop-filter: blur(6px) brightness(1.2) hue-rotate(45deg) saturate(2);
}

.bg-invert-blur-none {
  backdrop-filter: blur(0) brightness(1) hue-rotate(0deg) saturate(1);
}

.glowing-purple-text {
  text-shadow: 
    0 0 5px #FFF, 
    0 0 10px #FFF, 
    0 0 15px #FFF, 
    0 0 20px #fcc7ff, 
    0 0 30px #fcc7ff;
  will-change: text-shadow;
}

@media only screen and (max-width: 768px) {
  .glowing-purple-text {
    text-shadow:
    0 0 10px #FFF, 
    0 0 20px #fcc7ff;
  }
}

.glowing-purple-text-light {
  text-shadow: 0 0 3px #FFF, 0 0 5px #FFF, 0 0 7px #FFF, 0 0 10px #fcc7ff, 0 0 15px #fcc7ff, 0 0 20px #fcc7ff, 0 0 28px #fcc7ff, 0 0 37.5px #fcc7ff, 1px 1px 1px rgba(120,80,152,0);
}

.wavey-boi {
	/* color: #03a9f4; */
	animation: animate 4s ease-in-out infinite;
}

@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
      0% 45%,
      4% 44.75%,
      8% 44.5%,
      12% 44.25%,
      16% 44%,
      20.5% 45.5%,
      25% 47%,
      29% 48.5%,
      33% 50%,
      38.25% 52.5%,
      43.5% 55%,
      48.75% 57.5%,
      54% 60%,
      58% 60.25%,
      62% 60.5%,
      66% 60.75%,
      70% 61%,
      73.5% 60.5%,
      77% 60%,
      80.5% 59.5%,
      84% 59%,
      88% 57.25%,
      92% 55.5%,
      96% 53.75%,
      100% 52%,
      100% 100%,
      0% 100%
    );
  }

  50% {
    clip-path: polygon(
      0% 60%,
      3.75% 61.25%,
      7.5% 62.5%,
      11.25% 63.75%,
      15% 65%,
      19.75% 65.25%,
      24.5% 65.5%,
      29.25% 65.75%,
      34% 66%,
      38.25% 65%,
      42.5% 64%,
      46.75% 63%,
      51% 62%,
      55% 60%,
      59% 56%,
      63% 52%,
      67% 50%,
      71.75% 48.75%,
      75.5% 47.5%,
      79.75% 46.25%,
      84% 45%,
      88% 45.25%,
      92% 45.5%,
      96% 45.75%,
      100% 46%,
      100% 100%,
      0% 100%
    );
  }
}

.manifesto-part p {
  padding: 2.5rem 0;
}