.angel-image-frame {
  box-shadow: 0px 0px 24px 12px #fcc7ff;
}

.angel-image-frame:nth-of-type(1) {
    box-shadow: 0px 0px 24px 12px #fff3;
}

.angel-image-frame:nth-of-type(2) {
    box-shadow: 0px 0px 24px 12px #fff4;
}

.angel-image-frame:nth-of-type(3) {
    box-shadow: 0px 0px 24px 12px #fff;
}

.blur-panel {
  backdrop-filter: blur(12px)
}

.blur-panel-light {
  backdrop-filter: blur(2px)
}
