.text-container {
  padding: 7px;
}

.subheading {
  font-weight: 300;
}

.paragraph {
  font-weight: 300;
  font-size: 1.25em;
  margin-bottom: 24px;
}

@media only screen and (max-width: 768px) {
  .paragraph {
    font-size: 1.125em
  }
}

.subparagraph {
  font-weight: 600;
  font-size: 0.75em;
}

.text-container.chinese {
  font-size: 1.777em;
  width: 24px;
  display: inline-block;
}
